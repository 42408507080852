<template>
  <div class="chat-container vstack text">
    <template>
      <div class="messages-container -fit -scrollable">
        <div
          v-for="message in messages"
          :key="message.name + message.time + message.message"
          :style="
            ['display: flex;'] +
            [
              message.from_me
                ? 'flex-direction: row-reverse'
                : 'flex-direction: row',
            ]
          "
        >
          <p :class="message.from_me ? 'bg-light' : 'bg-dark'">
            <span style="font-weight: 500">{{ message.message }}</span>
          </p>
        </div>
      </div>
      <input
        placeholder="Escreva uma mensagem..."
        v-on:keyup.enter="sendMessage($event)"
        type="text"
        id="message-input"
      />
    </template>
  </div>
</template>

<script>
import { messages } from "@/lib/emitter"

export default {
  name: "app-chat",
  props: {
    name: {
      type: String,
    },
  },
  data() {
    return {
      messages: [],
    }
  },
  methods: {
    sendMessage(event) {
      let message = event.target.value
      messages.emit("chatMessage", { message })
      this.messages.push({
        message: message,
        from_me: true,
      })
      event.target.value = ""
    },
    scrollToEnd: function () {
      let messages = this.$el.querySelector(".messages-container")
      messages.scrollTop = messages.lastElementChild.offsetTop
    },
  },
  updated() {
    this.$nextTick(() => this.scrollToEnd())
  },
  mounted() {
    messages.on("newMessage", (info) => {
      this.messages.push({
        message: info.message,
        from_me: false,
      })
    })
  },
}
</script>

<style lang="scss">
@import "../css/chat.scss";
</style>
